<template>
  <div>
    <page-title :heading="'EventBee'" :icon=icon :subheading="$t('eventbee.lang_refundAndCancelation')"
    ></page-title>
    <div class="app-main__inner">
      <RefundAndCancellationComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import RefundAndCancellationComponent from "../../components/eventbee/RefundAndCancellationComponent";
export default {
  name: "RefundAndCancellation",
  components: {RefundAndCancellationComponent, PageTitle},
  data: () => ({
    icon: 'pe-7s-ticket icon-gradient bg-tempting-azure',
  })
}
</script>

<style scoped>

</style>