<template>
  <div class="mb-3 card">
    <v-row class="pt-0 mt-0 pa-2">
      <v-col
          cols="12"
          lg="6"
          md="6"
          sm="6"
      >
        <BaseDateInput
            hide-details
            type="date"
            dense
            outlined
            v-model="startDate"
            :label="$t('generic.lang_from')"
        />
      </v-col>
      <v-col
          cols="12"
          lg="6"
          md="6"
          sm="6"
      >
        <BaseDateInput
            hide-details
            type="date"
            dense
            outlined
            v-model="endDate"
            :label="$t('generic.lang_to')"
        />
      </v-col>
      <v-col cols="12">
        <v-btn block class="mx-auto elevation-0" color="primary" @click="$refs.refundsAndCancellations.getDataFromApi()">
          <v-icon>filter_list</v-icon>
          {{ $t('generic.lang_filter') }}
        </v-btn>
      </v-col>
    </v-row>
    <div>
      <!-- Table -->
      <Datatable v-if="showTable" ref="refundsAndCancellations"
                 :api-endpoint="ENDPOINTS.DATATABLES.EVENTBEE.REFUNDSANDCANCELLATIONS"
                 :datatable-headers="datatableHeaders"
                 :excel-columns="excelColumns"
                 :data="params"
                 excel-file-name="Verkäufe"
                 @displayEntry="displayEntry"
                 show-display-buttons
      >
        <template v-slot:item.total="{item}">
          {{ item.total | currency }}
        </template>
      </Datatable>


    </div>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard class="internalWidth" id="onScreenKeyboard" :options="touchKeyboard.options" :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible" :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </div>
</template>



<script>
import Datatable from "../datatable/Datatable";
import {ENDPOINTS} from '../../config'
import mixin from "../../mixins/KeyboardMixIns";
import BaseDateInput from "@/components/common/BaseDateInput.vue";

export default {
  name: "RefundAndCancellationComponent",
  components: {
    Datatable,
    BaseDateInput
  },
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      eventGroups: [],
      serviceProviders: [],
      id: null,
      voucherBalance: "",
      issuingDate: null,
      lastUse: null,
      voucherBalanceAdd: "",
      customerIdAdd: null,
      addSearch: null,
      items: [],
      search: "",
      loading: false,
      showTable: true,
      showCreate: false,
      showUpdate: false,
      tab: 0,
      startDate:null,
      endDate:null,
      type: -1,
      group: -1,
      provider: -1,
      voucherData: [],
      dataTable: null,
    }
  },
  computed:{
    params: function () {
      return {
        startDate: this.$moment(this.startDate, "YYYY-MM-DD").unix() || null,
        endDate: this.$moment(this.endDate, "YYYY-MM-DD").unix() || null
      }
    },
    datatableHeaders() {
      return [
        {
          text: this.$t('generic.lang_id'),
          align: 'left',
          value: "uuid",
          hide: true
        },
        {text: this.$t('generic.lang_id'), value: "id"},
        {text: this.$t('generic.lang_time') + " / " + this.$t('generic.lang_date'), value: "date"},
        {text: this.$t('generic.lang_seller_id'), value: "sellerID"},
        {text: this.$t('generic.lang_customer'), value: "cust"},
        {text: this.$t('erp.lang_stornoGrund'), value: "reason"},
        {text: this.$t('erp.lang_totalsolditems'), value: "totalItems"},
        {text: this.$t('erp.lang_ware_total'), value: "total"},
      ]
    },
    excelColumns() {
      return [
        {label: this.$t('generic.lang_id'), field: "id"},
        {label: this.$t('generic.lang_time') + " / " + this.$t('generic.lang_date'), field: "date"},
        {label: this.$t('generic.lang_seller_id'), field: "sellerID"},
        {label: this.$t('generic.lang_customer'), field: "cust"},
        {label: this.$t('erp.lang_stornoGrund'), field: "reason"},
        {label: this.$t('erp.lang_totalsolditems'), field: "totalItems"},
        {label: this.$t('erp.lang_ware_total'), field: "total"},
      ]
    }
  },
  watch: {
    tab: function() {
      if(this.tab === 1) {
        this.showTable = true;
        this.showUpdate = false;
      }
    }
  },

  methods: {
    displayEntry(entry) {
      this.uuid = entry.uuid;
      this.$router.push({name: 'accounting.DisplayInvoice', params: {id: this.uuid}})

    },

    resetData: function () {
      this.showCreate = false;
      this.showUpdate = false;
      this.showTable = true;
      this.id = null;
      this.text = "";
    },
    showList: function () {
      this.resetData();
      this.tab = 0;
    }
  },
  mounted() {
  }
}
</script>
